<template>
  <div class="d-flex mt-1" id="substepName">
    <a-input
      v-if="writingMode || isRenamingMode"
      class="ml-4 mr-2"
      :value="substep"
      placeholder="SubStep Name"
      @input="handleSubstepChange"
      @keydown.enter="toggleWritingMode"
      id="add-sub-step-input"
    />
    <label
      v-else
      @click="writingMode = true"
      :class="getLabelClass"
      id="edit-substep"
      >{{ substep || 'Substep' }}
      <small v-if="!substep">(Click to edit text)</small>
    </label>
    <span class="d-flex align-items-center mr-3">
      <a-button
        v-if="currentEditMode !== editModes.rename"
        size="small"
        class="ml-auto"
        :disabled="substepIndex === 0"
        @click="handleMoveSubstep('up')"
        data-toggle="tooltip"
        data-placement="top"
        title="Move Sub-Step one level up"
        id="move-substep-up-btn"
      >
        <template #icon>
          <arrow-up-outlined />
        </template>
      </a-button>
      <a-button
        v-if="currentEditMode !== editModes.rename"
        size="small"
        class="ml-2"
        :disabled="substepIndex + 1 === substepsCount(processIndex, stepIndex)"
        @click="handleMoveSubstep('down')"
        data-toggle="tooltip"
        data-placement="top"
        title="Move Sub-Step one level down"
        id="move-substep-down-btn"
      >
        <template #icon>
          <arrow-down-outlined />
        </template>
      </a-button>
    </span>
    <span>
      <a-button
        v-if="currentEditMode !== editModes.rename"
        type="danger"
        data-toggle="tooltip"
        data-placement="top"
        title="Delete Sub-step"
        @click="handleRemoveSubstep"
        id="remove-substep-btn"
      >
        <template #icon>
          <close-circle-outlined />
        </template>
      </a-button>
    </span>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import getUpdatedValue from '../../../../shared/Helpers/getSpacedUpdatedValue';
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons-vue';

export default {
  components: { ArrowDownOutlined, ArrowUpOutlined, CloseCircleOutlined },
  props: ['processIndex', 'stepIndex', 'substepIndex', 'substep'],
  data() {
    return {
      writingMode: false,
    };
  },
  computed: {
    ...mapGetters([
      'substepsCount',
      'currentEditMode',
      'editModes',
      'prevStepsJson',
    ]),
    getLabelClass() {
      const isAddDeleteMode = this.currentEditMode === this.editModes.addDelete;
      const isExistBefore = this.prevStepsJson[this.substep];
      return {
        unclickable: isAddDeleteMode && isExistBefore,
        'text-primary': isAddDeleteMode && !isExistBefore,
      };
    },

    isRenamingMode() {
      return this.currentEditMode === this.editModes.rename;
    },
  },

  methods: {
    ...mapActions(['updateSubstep', 'removeSubstep', 'moveSubstep']),
    toggleWritingMode() {
      if (this.isRenamingMode) return;
      this.writingMode = !this.writingMode;
    },

    handleSubstepChange(e) {
      const { value: substep } = e.target;
      const { substepIndex, stepIndex, processIndex } = this;
      this.updateSubstep({
        substep,
        substepIndex,
        stepIndex,
        processIndex,
      });
    },

    handleRemoveSubstep() {
      const { substepIndex, stepIndex, processIndex } = this;
      this.removeSubstep({
        substepIndex,
        stepIndex,
        processIndex,
      });
    },

    handleMoveSubstep(direction) {
      const { processIndex, stepIndex, substepIndex } = this;
      this.moveSubstep({
        processIndex,
        stepIndex,
        substepIndex,
        direction,
      });
    },

    handleSpace(e) {
      if (this.inputFlag) return;
      const el = e.target;
      const {
        processIndex,
        stepIndex,
        substepIndex,
        substep,
        updateSubstep,
      } = this;
      const { value, position } = getUpdatedValue(substep, el);
      updateSubstep({
        substep: value,
        substepIndex,
        stepIndex,
        processIndex,
      });
      this.$nextTick(() => {
        el.setSelectionRange(position, position);
      });
    },
  },
};
</script>
<style scoped>
.form-control {
  width: 90%;
  height: 30.44px;
}

label {
  color: #34495e;
  font-size: 0.875em;
  font-weight: 600;
  padding-left: 20px;
  cursor: pointer;
  width: 90%;
}

.btn-danger {
  height: 30.44px;
}

.icon {
  cursor: pointer;
  color: blue;
}

.icon-disabled {
  cursor: auto;
  color: lightgray;
}

.unclickable {
  pointer-events: none;
}
</style>
