<template>
  <a-card hoverable class="step-container" id="step-box">
    <div class="d-flex steprow" id="stepName">
      <a-input
        v-if="writingMode || isRenamingMode"
        class="mr-2 ml-2"
        :value="step"
        placeholder="Step Name"
        @input="handleStepChange"
        @keydown.enter="writingMode = false"
        id="step-name-input"
      />
      <label
        v-else
        @click="writingMode = true"
        :class="getLabelClass"
        id="edit-step"
        >{{ step || 'Step Name ' }}
        <small v-if="!step">(Click to edit text)</small>
      </label>
      <span class="d-flex mr-3 align-items-center">
        <a-button
          v-if="currentEditMode !== editModes.rename"
          size="small"
          :disabled="stepIndex === 0"
          @click="handleMoveStep('up')"
          data-toggle="tooltip"
          data-placement="top"
          title="Move Step one level up"
          id="move-step-up-btn"
        >
          <template #icon>
            <arrow-up-outlined />
          </template>
        </a-button>
        <a-button
          v-if="currentEditMode !== editModes.rename"
          size="small"
          class="ml-2"
          :disabled="stepIndex + 1 === stepsCount(processIndex)"
          @click="handleMoveStep('down')"
          data-toggle="tooltip"
          data-placement="top"
          title="Move Step one level down"
          id="move-step-down-btn"
        >
          <template #icon>
            <arrow-down-outlined />
          </template>
        </a-button>
      </span>

      <span>
        <a-button
          v-if="currentEditMode !== editModes.rename"
          type="danger"
          @click="handleRemoveStep"
          data-toggle="tooltip"
          data-placement="top"
          title="Delete Step"
          id="remove-step-btn"
        >
          <template #icon>
            <close-circle-outlined />
          </template>
        </a-button>
      </span>
    </div>
    <substep-component
      v-for="(substep, substepIndex) in newSubsteps(processIndex, stepIndex)"
      :key="substepIndex"
      :processIndex="processIndex"
      :stepIndex="stepIndex"
      :substepIndex="substepIndex"
      :substep="substep"
    ></substep-component>
    <div class="d-flex mt-2">
      <a-button
        v-if="currentEditMode !== editModes.rename"
        type="primary"
        class="ml-auto"
        @click="addSubstep({ stepIndex, processIndex })"
        data-toggle="tooltip"
        data-placement="top"
        title="Add Sub-Step"
        id="add-sub-step-btn"
      >
        <template #icon>
          <plus-circle-outlined />
        </template>
      </a-button>
    </div>
  </a-card>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import SubstepComponent from './SubstepComponent.vue';
import {
  ArrowUpOutlined,
  ArrowDownOutlined,
  CloseCircleOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons-vue';

export default {
  components: {
    SubstepComponent,
    ArrowUpOutlined,
    ArrowDownOutlined,
    PlusCircleOutlined,
    CloseCircleOutlined,
  },
  props: ['stepIndex', 'step', 'processIndex'],
  data() {
    return {
      writingMode: false,
    };
  },

  computed: {
    ...mapGetters([
      'editModes',
      'stepsCount',
      'newSubsteps',
      'prevStepsJson',
      'currentEditMode',
    ]),

    getLabelClass() {
      const isAddDeleteMode = this.currentEditMode === this.editModes.addDelete;
      const isExistBefore = this.prevStepsJson[this.step];
      return {
        unclickable: isAddDeleteMode && isExistBefore,
        'text-primary': isAddDeleteMode && !isExistBefore,
      };
    },

    isRenamingMode() {
      return this.currentEditMode === this.editModes.rename;
    },
  },
  methods: {
    ...mapActions(['updateStep', 'removeStep', 'addSubstep', 'moveStep']),
    toggleWritingMode() {
      if (this.isRenamingMode) return;
      this.writingMode = !this.writingMode;
    },

    handleStepChange(e) {
      const { value: step } = e.target;
      const { stepIndex, processIndex } = this;
      this.updateStep({
        step,
        stepIndex,
        processIndex,
      });
    },

    handleRemoveStep() {
      const { stepIndex, processIndex } = this;
      this.removeStep({
        stepIndex,
        processIndex,
      });
    },

    handleMoveStep(direction) {
      const { processIndex, stepIndex } = this;
      this.moveStep({
        processIndex,
        stepIndex,
        direction,
      });
    },
  },
};
</script>
<style scoped>
.form-control {
  width: 90%;
  height: 30.44px;
}

label {
  color: #34495e;
  font-size: 0.875em;
  font-weight: bold;
  cursor: pointer;
  width: 90%;
  margin-left: 0.5rem;
}

.btn-danger {
  height: 30.44px;
}

.icon {
  cursor: pointer;
  color: blue;
}

.icon-disabled {
  cursor: auto;
  color: lightgray;
}

.step-container {
  margin-bottom: 1em;
}

.unclickable {
  pointer-events: none;
}
</style>
